import { mapGetters, mapState } from 'vuex'
import FBADSMixin from '~/mixins/fb-ads'

export default {
  mixins: [
    FBADSMixin
  ],
  computed: {
    ...mapState(['cartSidebarOpen']),
    ...mapState('product', ['mainArea', 'planSelected', 'otherAreas', 'fastTrack', 'isGift', 'starterTestId', 'couponCode']),
    ...mapState('quiz', ['goalId', 'email']),
    ...mapState('user', ['accountCreated']),
    ...mapState('payment', ['paymentIntentSecret']),
    ...mapGetters({
      getTotalPrice: 'product/getTotalPrice',
      getTotalPayNowPrice: 'product/getTotalPayNowPrice',
      isQuizPlanResults: 'product/isQuizPlanResults',
      isQuizPlan: 'product/isQuizPlan',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      isBaseImprovementPlanPayUpfront: 'product/isBaseImprovementPlanPayUpfront',
      isMonitorPlan: 'product/isMonitorPlan'
    }),
    isStripeDisabled () {
      return !this.$stripe
    }
  },
  methods: {
    async handleAccountCreated (payerEmail) {
      if (!this.accountCreated) {
        await this.$store.dispatch('user/setAccountCreated', payerEmail)
      }
    },
    async confirmPayment ({ elements, returnUrl = 'https://get-base.com/successful-payment' }) {
      const { error } = await this.$stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl
        }
      })
      if (error) {
        if (window.DD_RUM) {
          window.DD_RUM.addError(error, {
            categorySelected: this.mainArea,
            planSelected: this.planSelected,
            addedStarterTests: this.otherAreas,
            deepDiveAreas: this.fastTrack ? [this.fastTrack] : null
          })
        }
        this.$toast.error(error.message)
      }
    },
    async confirmSetupIntent ({ elements, returnUrl = 'https://get-base.com/successful-payment' }) {
      const { error } = await this.$stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl
        }
      })
      if (error) {
        if (window.DD_RUM) {
          window.DD_RUM.addError(error, {
            categorySelected: this.mainArea,
            planSelected: this.planSelected,
            addedStarterTests: this.otherAreas,
            deepDiveAreas: this.fastTrack ? [this.fastTrack] : null
          })
        }
        this.$toast.error(error.message)
      }

      return error
    },
    async handleUserSubscribed ({ customerData, event = null, totalPrice, isCardPayment = true, paymentElement = null }) {
      if (customerData) {
        const { userID: userId } = customerData
        await this.$store.dispatch('user/setUserId', userId)
        if (event) {
          event.complete('success')
          const { payerEmail } = event
          await this.handleAccountCreated(payerEmail)
        }
        this.orderComplete(totalPrice)
      }
    },
    orderComplete () {
      window.location.href = '/successful-payment'
    },
    orderGiftComplete (totalPrice) {
      this.trackGAEvent({ action: 'purchased gift', category: 'payment', label: 'Checkout Gift flow completed', value: totalPrice })
      window.location.href = '/thanks'
    }
  }
}
