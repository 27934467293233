export const SUBSCRIBE_ENDPOINT = '/web/subscription'
export const PAYMENT_ENDPOINT = '/web/payment'
export const PAYMENT_ENDPOINT_INFO = '/web/payment/info'
export const RESET_PASSWORD_ENDPOINT = '/web/password/request'
export const NEW_PASSWORD_ENDPOINT = '/web/user/password/reset'
export const SUBSCRIBE_KLAVIYO_ENDPOINT = '/web/subscribe/klaviyo'
export const USER_SIGNUP_ENDPOINT = '/web/user/signup'
export const USER_GOOGLE_SIGNUP_ENDPOINT = '/web/auth/registerGoogleToken'
export const STRIPE_SETUP_INTENT = '/web/setupIntent'
export const QUIZ_RESULTS_DATA_ENDPOINT = '/web/quiz/result'
export const SET_COLLECTION_CHOICE_ENDPOINT = '/web/subscription/setCollectionChoice'
export const CHECK_COUPON_CODE_ENDPOINT = '/web/subscription/checkCouponCode'
export const PURCHASE_GIFTS_ENDPOINT = '/web/purchase/gift'
export const B2B_CONTACT_ENDPOINT = '/web/b2b/form'
export const WHITEPAPER_CONTACT_ENDPOINT = '/web/whitepaper/form'
export const PRACTITIONERS_CONTACT_ENDPOINT = '/web/practitioners/form'
export const PRACTITIONERS_REFERRAL_CONTACT_ENDPOINT = '/web/practitioner_referral/form'
export const PRACTITIONERS_PURCHASE_TESTS_ENDPOINT = '/web/practitioners/purchase/tests'
export const KLAVIYO_SUBSCRIBE_ENDPOINT = '/web/subscribe/klaviyo'
export const KLAVIYO_IDENTIFY_ENDPOINT = 'https://a.klaviyo.com/api/identify'
export const KLAVIYO_TRACK_ENDPOINT = 'https://a.klaviyo.com/api/track'
