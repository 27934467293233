//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    /**
     * Button theme: light | dark | tertiary
     */
    theme: {
      type: String,
      default: 'light',
      validator (value) {
        return ['light', 'dark', 'tertiary'].includes(value)
      }
    },
    /**
     * Button text
     */
    text: {
      type: String,
      required: true
    },
    /**
     * Button text alignment: center | left
     */
    alignText: {
      type: String,
      default: 'center',
      validator (value) {
        return ['left', 'center'].includes(value)
      }
    },
    /**
     * Button href
     */
    href: {
      type: String,
      default: null
    },
    /**
     * Whether the button has right arrow
     */
    arrow: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the button width is 100%
     */
    fluid: {
      type: Boolean,
      default: false
    },
    /**
     * Button cursor type: pointer | dot
     */
    cursor: {
      type: String,
      default: 'pointer'
    },
    /**
     * Whether the button has hover background effect
     */
    hover: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the button has prefetch
     */
    prefetch: {
      type: Boolean,
      default: true
    },
    /**
     * Whether the button emit a submit event
     */
    submit: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the button is disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Font size: sm | md | lg
     */
    font: {
      type: String,
      default: 'md'
    },
    capitalizeText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    component () {
      if (this.submit) {
        return 'button'
      } else if (this.href && this.prefetch) {
        return 'nuxt-link'
      }
      return this.href ? 'a' : 'div'
    },
    type () {
      return this.submit ? 'submit' : ''
    },
    cssClasses () {
      return {
        'base-button--hover': this.hover,
        'base-button--light': this.isLightTheme,
        'base-button--dark': this.isDarkTheme,
        'base-button--tertiary': this.isTertiaryTheme,
        'base-button--cursor-dot': this.isCursorDot,
        'base-button--cursor-pointer': this.isCursorPointer,
        'base-button--disabled': this.disabled,
        'd-flex': this.fluid,
        'd-inline-flex': !this.fluid,
        'fs-26 lh-30 px-24 py-8': this.isFontLG,
        'fs-22 lh-25 px-8 py-8': this.isFontMD,
        'fs-16 lh-19 px-8 py-8': this.isFontSM
      }
    },
    textClasses () {
      return {
        'mx-auto': this.alignText === 'center'
      }
    },
    isLightTheme () {
      return this.theme === 'light'
    },
    isDarkTheme () {
      return this.theme === 'dark'
    },
    isTertiaryTheme () {
      return this.theme === 'tertiary'
    },
    isCursorPointer () {
      return this.cursor === 'pointer'
    },
    isCursorDot () {
      return this.cursor === 'dot'
    },
    isFontMD () {
      return this.font === 'md'
    },
    isFontLG () {
      return this.font === 'lg'
    },
    isFontSM () {
      return this.font === 'sm'
    }
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        if (!this.href) {
          e.preventDefault()
        }
        this.$emit('click')
      }
    }
  }
}
