import PaynowPaymentMethod from './paynow-payment-method'
import SubscribeUser from '~/mixins/subscribe/user'

export default {
  mixins: [PaynowPaymentMethod, SubscribeUser],
  methods: {
    getPayNowPrice () {
      return Math.round((this.cartSidebarOpen ? this.getTotalPrice : this.getTotalPayNowPrice) * 100)
    },
    async createPayNowPayment ({ event }) {
      const { payerEmail, paymentMethod } = event
      const categorySelected = this.cartSidebarOpen ? this.mainArea : this.getPayNowArea
      const plan = this.cartSidebarOpen ? this.planSelected : this.getPayNowPlan
      const code = sessionStorage.getItem('code')
      const totalPrice = this.cartSidebarOpen ? this.getTotalPrice : this.getTotalPayNowPrice
      const createCustomerData = await this.subscribeUser({
        email: payerEmail,
        payNowEvent: event,
        mainArea: categorySelected,
        isDeepDive: !!this.fastTrack,
        quizEmail: this.email,
        goalId: this.goalId,
        starterTestId: this.starterTestId,
        paymentMethod: paymentMethod,
        plan: plan,
        code: code
      })
      await this.$store.dispatch('product/setPaidWithPayNowInArea', !this.cartSidebarOpen)
      await this.handleUserSubscribed({ customerData: createCustomerData, event, totalPrice })
    }
  }
}
