export default {
  setReferralLink ({ commit }, referralLink) {
    commit('setReferralLink', referralLink)
  },
  setCollectionChoice ({ commit }, collectionChoice) {
    commit('setCollectionChoice', collectionChoice)
  },
  setAccountCreated ({ commit }, accountCreated) {
    commit('setAccountCreated', accountCreated)
  },
  setUserId ({ commit }, userId) {
    commit('setUserId', userId)
  }
}
