import { SUBSCRIBE_KLAVIYO_ENDPOINT } from '@/constants/endpoints'

export default {
  methods: {
    async subscribeEmail ({ email, listID }) {
      const data = {
        email
      }
      if (listID) {
        data.listID = listID
      }
      try {
        await this.$axios.$post(SUBSCRIBE_KLAVIYO_ENDPOINT, data)
      } catch (e) {
        return
      }
      return true
    }
  }
}
