export default {
  setFBClidCreationTime ({ commit }, fbclidCreationTime) {
    commit('setFBClidCreationTime', fbclidCreationTime)
  },
  setFBClid ({ commit }, fbclid) {
    commit('setFBClid', fbclid)
  },
  setFBEventSuffixId ({ commit }, fbEventSuffixId) {
    commit('setFBEventSuffixId', fbEventSuffixId)
  }
}
