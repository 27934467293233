import fb from './fb'
import product from './product'
import quiz from './quiz'
import user from './user'
import payment from './payment'

const initialState = {
  fb: fb.state(),
  product: product.state(),
  quiz: quiz.state(),
  user: user.state(),
  payment: payment.state()
}

export const state = () => ({
  cartSidebarOpen: false
})

export const mutations = {
  setCartSidebarOpen (state, open) {
    state.cartSidebarOpen = open
  },
  resetState (state) {
    Object.keys(state).forEach(key => Object.assign(state[key], initialState[key]))
  }
}
