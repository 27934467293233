//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubscribeEmail from '~/mixins/subscribe/email'

export default {
  mixins: [
    SubscribeEmail
  ],
  data () {
    return {
      email: null,
      showMessage: false
    }
  },
  computed: {
    sections () {
      return this.$mq === 'lg' ? this.$t('practitioners.footer.sections') : this.$t('practitioners.footer.sectionsMobile')
    },
    showGiftImage () {
      return this.$route.path === '/gifts'
    }
  },
  methods: {
    async submitForm () {
      await this.subscribeEmail({ email: this.email, listID: 'YgyfVu' })
      this.showMessage = true
      this.trackEmailSubscribed()
    },
    trackEmailSubscribed () {
      this.addDDAction('Email subscribed', {
        email: this.email,
        path: this.$route.path,
        listID: 'YgyfVu',
        section: 'footer'
      })
    },
    trackLink (link) {
      this.addDDAction('Footer link clicked', {
        link,
        path: this.$route.path
      })
    }
  }
}
