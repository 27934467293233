import { render, staticRenderFns } from "./remove-quiz-plan-modal.vue?vue&type=template&id=512efbe6&"
import script from "./remove-quiz-plan-modal.vue?vue&type=script&lang=js&"
export * from "./remove-quiz-plan-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/home/runner/work/webpage/webpage/components/base/button/button.vue').default,BaseModal: require('/home/runner/work/webpage/webpage/components/base/modal/modal.vue').default})
