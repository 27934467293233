export default {
  setReferralLink (state, referralLink) {
    state.referralLink = referralLink
  },
  setCollectionChoice (state, collectionChoice) {
    state.collectionChoice = collectionChoice
  },
  setAccountCreated (state, accountCreated) {
    state.accountCreated = accountCreated
  },
  setUserId (state, userId) {
    state.userId = userId
  }
}
