export default {
  setFBClidCreationTime (state, fbclidCreationTime) {
    state.fbclidCreationTime = fbclidCreationTime
  },
  setFBClid (state, fbclid) {
    state.fbclid = fbclid
  },
  setFBEventSuffixId (state, fbEventSuffixId) {
    state.fbEventSuffixId = fbEventSuffixId
  }
}
