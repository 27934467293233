import { render, staticRenderFns } from "./logo-legal-footer.vue?vue&type=template&id=74287e46&"
import script from "./logo-legal-footer.vue?vue&type=script&lang=js&"
export * from "./logo-legal-footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNavBar: require('/home/runner/work/webpage/webpage/components/base/nav-bar/nav-bar.vue').default,BaseFooterLegal: require('/home/runner/work/webpage/webpage/components/base/footer-legal/footer-legal.vue').default})
