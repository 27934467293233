//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import CartSidebarPaynowPaymentMethod from '~/mixins/stripe/paynow/cart-sidebar'
import IntercomMixin from '~/mixins/intercom'

export default {
  mixins: [
    IntercomMixin,
    CartSidebarPaynowPaymentMethod
  ],
  computed: {
    ...mapState('product', ['planSelected', 'areaPrice', 'mainArea', 'otherAreas', 'fastTrack', 'isGift', 'fastTrackTests', 'starterTest']),
    ...mapGetters({
      getTotalProducts: 'product/getTotalProducts',
      isQuizPlanResults: 'product/isQuizPlanResults',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      isMonitorPlan: 'product/isMonitorPlan',
      isBaseCompleteImprovementPlanPayUpfront: 'product/isBaseCompleteImprovementPlanPayUpfront',
      isBaseImprovementPlanPayUpfront: 'product/isBaseImprovementPlanPayUpfront'
    }),
    headerTitle () {
      return this.$t('general.cartSidebar.header', { totalItems: this.getTotalProducts })
    },
    showExpandedTestPopup () {
      return !this.fastTrack && this.fastTrackTests.length > 0 && (this.isQuizPlanResults || this.isBaseImprovementPlan)
    },
    checkoutButtonLink () {
      if (this.showExpandedTestPopup) {
        return null
      } else if (this.isGift) {
        return '/checkout-payment-gift'
      } else if (this.$route.path.includes('/test/')) {
        return '/checkout-payment-b'
      } else if (this.isBaseImprovementPlanPayUpfront || this.isBaseCompleteImprovementPlanPayUpfront) {
        return '/checkout-b'
      } else {
        return '/checkout-payment'
      }
    },
    isPayUpfrontActiveAb () {
      return this.isABActivated('pay-upfront-project')
    }
  },
  methods: {
    onCartHidden () {
      this.updateIntercomSettings()
      this.$store.commit('setCartSidebarOpen', false)
    },
    onCartShown () {
      this.shutdownIntercomWidget()
      this.addDDAction('Cart sidebar open', { path: this.$route.path })
      this.$store.commit('setCartSidebarOpen', true)
    },
    openExpandedTestPopup () {
      this.$root.$emit('bv::show::modal', 'fastTrackModal')
    },
    trackCheckout () {
      this.addDDAction('Checkout button clicked', {
        section: 'cart',
        path: this.$route.path
      })
    }
  }
}
