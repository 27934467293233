/* eslint-disable */
export default () => {
  (function(a,b,c,d,e,f,g) {
    e['ire_o']=c;
    e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};
    f=d.createElement(b);g=d.getElementsByTagName(b)[0];
    f.async=1;
    f.src=a;
    g.parentNode.insertBefore(f,g);
  })('https://utt.impactcdn.com/A3304612-b21e-439f-946c-e643f056f3311.js','script','ire',document,window)
  ire('identify', { customerid: '' , customeremail: '' });
}
