import { sha256 } from 'js-sha256'
import { SUBSCRIBE_ENDPOINT } from '@/constants/endpoints'
import { DEFAULT_ERROR_MESSAGE, STRIPE_CARD_ERRORS } from '~/constants/errorMessage'
import GAEcommerce from '~/mixins/ga-ecommerce'

export default {
  mixins: [GAEcommerce],
  methods: {
    async subscribeUser ({
      paymentMethod,
      email,
      payNowEvent = null,
      mainArea,
      plan,
      code,
      quizEmail,
      goalId,
      starterTestId,
      isDeepDive,
      userType
    }) {
      const fbEventSuffixId = this.createFBEventSuffixId(email)
      const fbEventId = `purchase${fbEventSuffixId}`
      const subscribeFbEventId = `subscribe${fbEventSuffixId}`
      const actionSource = 'website'
      const clientUserAgent = navigator.userAgent
      const eventSourceUrl = window.location.href
      const em = sha256(email)
      const fbc = this.createFBC()
      const fbp = this.createFacebookPixelID()
      const utmSource = sessionStorage.getItem('utmSource') || ''
      const utmCampaign = sessionStorage.getItem('utmCampaign') || ''
      const utmMedium = sessionStorage.getItem('utmMedium') || ''
      const externalId = parseInt(localStorage.getItem('FBExternalID'))
      const gaData = {
        transactionId: this.createTransactionId(),
        code,
        currency: 'USD',
        items: JSON.stringify(this.createEcommerceItems())
      }

      await this.$store.dispatch('fb/setFBEventSuffixId', fbEventSuffixId)

      const body = {
        paymentMethod: paymentMethod.id,
        plan,
        email,
        code,
        gaData,
        fbData: {
          is_new_billing_module: this.isABActivated('new-billing'),
          external_id: externalId,
          action_source: actionSource,
          client_user_agent: clientUserAgent,
          event_source_url: eventSourceUrl,
          event_id: fbEventId,
          subscribe_event_id: subscribeFbEventId,
          event_timestamp: Math.floor(Date.now() / 1000),
          em,
          fbc,
          fbp,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign
        }
      }

      body.planDetails = {
          quizEmail,
          goalID: goalId,
          starterTestID: starterTestId,
          isDeepDive
      }


      const headers = {
        'Content-Type': 'application/json'
      }
      const bodyStr = JSON.stringify(body)
      const endpoint = SUBSCRIBE_ENDPOINT
      try {
        return await this.$axios.$post(endpoint, bodyStr, { headers })
      } catch (e) {
        const { response: { status, data: { code = '', message = '' } = {} } } = e
        const isUserAlreadySubscribed = status === 400 && code === 'USER_ALREADY_SUBSCRIBED'
        const errorProps = {
          mainArea,
          plan,
          error: {
            code,
            status
          }
        }
        if (isUserAlreadySubscribed) {
          if (payNowEvent) {
            payNowEvent.complete('success')
          }
          this.$toast.error(`${message}. Please contact us at members@get-base.com`)
        } else if (STRIPE_CARD_ERRORS.has(code)) {
          this.$toast.error(`${STRIPE_CARD_ERRORS.get(code)}`)
          this.addDDError(e, errorProps)
        } else {
          this.addDDError(e, errorProps)
          this.$toast.error('Sorry something happened. Shoot us a message at members@get-base.com so we can fix it or try again.')
          throw new Error(DEFAULT_ERROR_MESSAGE)
        }
        return status
      }
    }
  }
}
