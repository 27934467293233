export default {
  setPaymentIntentSecret (state, paymentIntentSecret) {
    state.paymentIntentSecret = paymentIntentSecret
  },
  setTotal (state, total) {
    state.total = total
  },
  setSubtotal (state, subtotal) {
    state.subtotal = subtotal
  },
  setCoupon (state, { name, percentageOff, amountOff }) {
    state.coupon = {
      name,
      percentageOff,
      amountOff
    }
  },
  setPaymentType (state, paymentType) {
    state.paymentType = paymentType
  }
}
