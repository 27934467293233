/* eslint-disable */
export default () => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) { return } n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments)
    }
    if (!f._fbq) { f._fbq = n }n.push = n; n.loaded = !0; n.version = '2.0'
    n.queue = []; t = b.createElement(e); t.async = !0
    t.src = v; s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js'))
  const isTrafficExcluded = Boolean(localStorage.getItem('excludeGATraffic'))
  if (!isTrafficExcluded) {
    const externalId = Math.floor(Math.random() * 10000000) + 1
    localStorage.setItem('FBExternalID', `${externalId}`)
    fbq('init', '2988715947807968', {'external_id': externalId})
    fbq('track', 'PageView')
  }
}
