import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      currency: 'USD'
    }
  },
  computed: {
    ...mapState('product', ['planSelected', 'mainArea', 'otherAreas', 'fastTrack', 'paidWithPayNowInArea']),
    ...mapGetters({
      getPlanPrice: 'product/getPlanPrice',
      getFastTrackPrice: 'product/getFastTrackPrice',
      getTotalPrice: 'product/getTotalPrice',
      getTotalPayNowPrice: 'product/getTotalPayNowPrice',
      getPayNowPlan: 'product/getPayNowPlan',
      getPayNowArea: 'product/getPayNowArea'
    })
  },
  methods: {
    createTransactionId () {
      const now = Date.now()
      const randomNumber = Math.floor(Math.random() * 100000)
      return `T${now}${randomNumber}`
    },
    createEcommerceProduct ({ plan, area, variant = '', quantity, price }) {
      const isBaseCompleteResults = plan === 'base-complete-results'
      const isBaseCompleteImprovementPlan = plan === 'base-complete-improvement-plan'
      const id = isBaseCompleteResults || isBaseCompleteImprovementPlan ? plan : `${plan}-${area}${variant ? `-${variant}` : ''}`
      const name = isBaseCompleteResults || isBaseCompleteImprovementPlan ? plan : `${plan} ${area}${variant ? `-${variant}` : ''}`
      const priceStr = `${price}`
      return {
        currency: this.currency,
        item_id: id,
        item_name: name,
        item_category: plan,
        item_category2: area,
        quantity,
        variant,
        price: priceStr
      }
    },
    createEcommerceItems () {
      const plan = this.paidWithPayNowInArea ? this.getPayNowPlan : this.planSelected
      const area = this.paidWithPayNowInArea ? this.getPayNowArea : this.mainArea

      const mainProduct = this.createEcommerceProduct({
        price: this.getPlanPrice,
        plan,
        area,
        quantity: 1
      })

      const items = [
        mainProduct
      ]

      if (this.fastTrack) {
        const expandedTestProduct = this.createEcommerceProduct({
          plan: this.planSelected,
          price: this.getFastTrackPrice,
          quantity: 1,
          area: this.fastTrack,
          variant: 'expanded'
        })

        items.push(expandedTestProduct)
      }

      if (this.otherAreas.length > 0) {
        this.otherAreas.forEach((otherArea) => {
          const otherAreaProduct = this.createEcommerceProduct({
            plan: this.planSelected,
            price: this.getPlanPrice,
            quantity: 1,
            area: otherArea
          })

          items.push(otherAreaProduct)
        })
      }

      return items
    },
    trackEcommerceEvent (action, properties, delay = false) {
      const timeToDelay = delay ? 1001 : 0
      if (window.gtag) {
        setTimeout(() => window.gtag('event', action, properties), timeToDelay)
      }
    },
    trackGAEcommerceAddToCart ({ area, plan = 'base-monitor' }) {
      const action = 'add_to_cart'
      const productToAdd = this.createEcommerceProduct({
        price: this.getPlanPrice,
        quantity: 1,
        plan,
        area
      })
      const items = [productToAdd]
      const properties = { items }
      this.trackEcommerceEvent(action, properties)
    },
    trackGAEcommerceRemoveFromCart ({ area, plan }) {
      const action = 'remove_from_cart'
      const productToRemove = this.createEcommerceProduct({
        price: this.getPlanPrice,
        quantity: 1,
        plan,
        area
      })
      const items = [productToRemove]
      const properties = { items }
      this.trackEcommerceEvent(action, properties)
    },
    trackGoogleAnalyticsEcommerceBeginCheckout () {
      const action = 'begin_checkout'
      const price = this.paidWithPayNowInArea ? this.getTotalPayNowPrice : this.getTotalPrice
      const items = this.createEcommerceItems()
      const coupon = sessionStorage.getItem('rc') || ''
      const properties = {
        value: price,
        currency: this.currency,
        items,
        coupon
      }

      this.trackEcommerceEvent(action, properties)
    },
    trackGoogleAnalyticsEcommerceSubscription ({ couponCode }) {
      const action = 'purchase'
      const transactionId = this.createTransactionId()
      const tax = 0
      const shipping = 0
      const items = this.createEcommerceItems()
      const price = this.paidWithPayNowInArea ? this.getTotalPayNowPrice : this.getTotalPrice
      const properties = {
        transaction_id: transactionId,
        value: price,
        coupon: couponCode,
        currency: this.currency,
        tax,
        shipping,
        items
      }

      this.trackEcommerceEvent(action, properties, true)
    }
  }
}
