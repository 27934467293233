//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    /**
     * Text to show
     */
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showBanner: true
    }
  },
  methods: {
    onClose () {
      this.showBanner = false
    }
  }
}
