import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('fb', ['fbclid', 'fbclidCreationTime'])
  },
  methods: {
    createFBC () {
      return this.fbclid && this.fbclidCreationTime ? `fb.1.${this.fbclidCreationTime}.${this.fbclid}` : null
    },
    createFacebookPixelID () {
      const currentTime = Date.now()
      const randomDigits = Math.floor(Math.random() * 1000000000)
      return `fb.1.${currentTime}.${randomDigits}`
    },
    createFBEventSuffixId (email) {
      const currentTime = Date.now()
      return `${email}${currentTime}`
    }
  }
}
