import { render, staticRenderFns } from "./logo-clickable.vue?vue&type=template&id=59d6bab4&"
import script from "./logo-clickable.vue?vue&type=script&lang=js&"
export * from "./logo-clickable.vue?vue&type=script&lang=js&"
import style0 from "./logo-clickable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBanner: require('/home/runner/work/webpage/webpage/components/base/banner/banner.vue').default,BaseNavBar: require('/home/runner/work/webpage/webpage/components/base/nav-bar/nav-bar.vue').default})
