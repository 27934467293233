export default {
  setPayment ({ commit }, { paymentIntentSecret, total, subtotal, coupon, percentageOff, amountOff }) {
    commit('setPaymentIntentSecret', paymentIntentSecret)
    commit('setTotal', total)
    commit('setSubtotal', subtotal)
    if (coupon) {
      commit('setCoupon', {
        name: coupon,
        percentageOff,
        amountOff
      })
    }
  }
}
