//
//
//
//
//
//
//

import ProductConfigurationMixin from '@/mixins/product-configuration'

export default {
  mixins: [ProductConfigurationMixin]
}
