import { datadogRum } from '@datadog/browser-rum'

const initDatadogRum = () => {
  datadogRum.init({
    applicationId: '0423cbb3-4f28-4282-ac72-9fed080d4d6a',
    clientToken: 'pub4f3a50eb1ada919f1e5ef2ffc1ebf2cb',
    site: 'datadoghq.com',
    service: 'base-website',
    env: 'production',
    version: 'v1.23.0',
    sampleRate: 100,
    trackInteractions: true,
    beforeSend: (event, context) => {
      if (event.type === 'error') {
        const errorMessage = event.error.message
        if (errorMessage.includes('Object Not Found Matching') ||
          errorMessage.includes('isTrusted') ||
          errorMessage.includes('Script error') ||
          errorMessage.includes('Illegal invocation') ||
          errorMessage.includes('Cannot resolve iframe with unknown index') ||
          errorMessage.includes('[analytics.js] Failed to load Analytics.js ChunkLoadError:') ||
          errorMessage.includes('Empty reason') ||
          errorMessage.includes('instantSearchSDKJSBridgeClearHighlight')) {
          return false
        }
      }
    }
  })
  datadogRum.startSessionReplayRecording()
}

export default (context) => {
  if (!context.isDev) {
    initDatadogRum()
  }
}
