//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubscribeEmail from '~/mixins/subscribe/email'

export default {
  mixins: [
    SubscribeEmail
  ],
  data () {
    return {
      email: null,
      showMessage: false
    }
  },
  computed: {
    sections () {
      return this.$t('general.footerLegal.sections')
    }
  },
  methods: {
    trackLink (link) {
      this.addDDAction('Footer link clicked', {
        link,
        path: this.$route.path
      })
    }
  }
}
