//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    /**
     * Modal id
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Modal size: sm | md | lg
     */
    size: {
      type: String,
      default: 'md'
    },
    /**
     * Whether the modal is visible (v-model)
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * Modal theme color: light | dark
     */
    theme: {
      type: String,
      default: 'light',
      validator: val => ['light', 'dark'].includes(val)
    },
    /**
     * Whether to load the html before open the modal
     */
    staticLoad: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to show the close button
     */
    showClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    contentClasses () {
      return [
        'base-modal__content',
        {
          'base-modal__content--light': this.isLightTheme(),
          'base-modal__content--dark': this.isDarkTheme()
        }
      ]
    }
  },
  methods: {
    isLightTheme () {
      return this.theme === 'light'
    },
    isDarkTheme () {
      return this.theme === 'dark'
    },
    closeModal () {
      this.$root.$emit('bv::hide::modal', this.id)
    }
  }
}
