export default () => ({
  planSelected: 'base-improvement-plan',
  starterTest: null,
  starterTestId: -1,
  mainArea: null,
  areaToAdd: null,
  planToAdd: null,
  starterTestToAdd: null,
  starterTestIdToAdd: null,
  otherAreas: [],
  testForGoalIDs: [],
  isGift: false,
  planPrice: {
    'base-improvement-plan': 79.95,
    'base-complete-improvement-plan': 520.00
  },
  fastTrackPrices: {
    diet: 210,
    stress: 150,
    energy: 210,
    'sex-drive': 150
  },
  areaPrice: 79.95,
  improvementProgramPrice: null,
  foodTestPrice: 127.00,
  fastTrack: null,
  fastTrackTests: [],
  fastTrackTestsAreas: null,
  paidWithPayNowInArea: false,
  payNow: {
    plan: null,
    area: null
  },
  couponCode: {
    name: null,
    type: null,
    value: null,
    active: false
  },
  testMenu: null,
  baseCompleteTests: [],
  giftPricing: {
    one_off: 79.95,
    improvement_program: 259.95
  }
})
