export const DEFAULT_ERROR_MESSAGE = 'Sorry something happened. Shoot us a message so we can fix it or try again.'
export const STRIPE_CARD_ERRORS = new Map([
  ['generic_decline', 'Card declined'],
  ['card_declined', 'Card declined'],
  ['insufficient_funds', 'Insufficient funds'],
  ['lost_card', 'Card reported as lost'],
  ['stolen_card', 'Card reported as stolen'],
  ['expired_card', 'Card expired'],
  ['incorrect_cvc', 'Incorrect CVC'],
  ['processing_error', 'Processing error'],
  ['incorrect_number', 'Incorrect number'],
  ['unknown_error', 'Payment error']
])
