export default () => {
  const APP_ID = 'c45ld6e0'

  window.intercomSettings = {
    app_id: APP_ID,
    api_base: 'https://api-iam.intercom.io',
    vertical_padding: 85
  };
  (function () {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      // const intercomButton = d.getElementById('openIntercom')
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + APP_ID
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
        // window.Intercom('show')
        // setTimeout(() => intercomButton.setAttribute('style', 'display: none;'), 280)
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}
