import { mapGetters, mapState } from 'vuex'
import GetData from '@/mixins/areas/get-data'

export default {
  mixins: [GetData],
  async fetch () {
    const data = await this.getData(this.email)
    if (data) {
      const { areas, baseComplete, improvementProgramPrice } = data
      const fastTrackTestsAreas = {}
      const testMenuData = {}

      areas.forEach((area) => {
        fastTrackTestsAreas[this.normalizeAreaName(area.area.name)] = area.tests.map(test => test.testTitle)
        testMenuData[this.normalizeAreaName(area.area.name)] = area.tests
        this.$store.commit('product/setFastTrackTestsPrice', { area: this.normalizeAreaName(area.area.name), price: area.deepDivePrice })
        this.$store.commit('product/setPlanPrice', area.price)
      })
      this.$store.commit('product/setFastTrackTestsAreas', fastTrackTestsAreas)
      this.$store.commit('product/setTestMenu', testMenuData)
      this.$store.commit('product/setBaseCompletePrice', baseComplete.price)
      this.$store.commit('product/setBaseCompleteTests', baseComplete.tests)
      this.$store.commit('product/setImprovementProgramPrice', improvementProgramPrice)
    }
  },
  fetchOnServer: false,
  computed: {
    ...mapState('product', ['mainArea']),
    ...mapState('quiz', ['email']),
    ...mapGetters({
      getPlanPriceRaw: 'product/getPlanPriceRaw'
    })
  },
  async mounted () {
    await this.$store.dispatch('product/initProduct')
  },
  methods: {
    normalizeAreaName (area) {
      const areaNormalized = area.toLowerCase()
      if (areaNormalized === 'sexdrive') {
        return 'sex-drive'
      } else {
        return areaNormalized
      }
    }
  }
}
