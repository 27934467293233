import { CHECK_COUPON_CODE_ENDPOINT } from '@/constants/endpoints'
import { DEFAULT_ERROR_MESSAGE } from '~/constants/errorMessage'

export default {
  methods: {
    async getCouponData (coupon) {
      try {
        return await this.$axios.$post(CHECK_COUPON_CODE_ENDPOINT, { code: coupon })
      } catch (e) {
        throw new Error(DEFAULT_ERROR_MESSAGE)
      }
    }
  }
}
