export const GOAL_ID_BY_TYPE = {
  TO_IMPROVE: [0, 1, 10, 28, 30, 31, 2, 3, 27, 32, 5, 6, 7, 11, 29],
  TO_MANAGE: [25, 23, 24, 26, 8, 9]
}

export const GOAL_ID_TO_GOAL_KEY = {
  0: 'fatigue',
  2: 'weight-management',
  22: 'hormonal-imbalances',
  31: 'menopause',
  4: 'digestive-issues'
}
