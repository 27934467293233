//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { GOAL_ID_TO_GOAL_KEY } from '@/constants/goalIdByType'

export default {
  data () {
    return {
      isGiftQuiz: this.$route.query.isGiftQuiz === 'true'
    }
  },
  computed: {
    ...mapState('product', ['fastTrackTests', 'fastTrackPrices', 'mainArea', 'isGift']),
    ...mapState('quiz', ['goalId']),
    ...mapGetters({
      isFastTrackSelected: 'product/isFastTrackSelected',
      isBaseImprovementPlanPayUpfront: 'product/isBaseImprovementPlanPayUpfront',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan'
    }),
    image () {
      let keyGoal = GOAL_ID_TO_GOAL_KEY[this.goalId]

      if (!keyGoal) {
        keyGoal = 'default' // Default value
      }

      return this.getStaticsObject(`/quiz-results/expanded_test_module_${keyGoal}.jpg`)
    }
  },
  watch: {
    mainArea (value) {
    },
    fastTrackPrices (value) {
    },
    goalId (value) {
    }
  },
  methods: {
    checkoutLink () {
      if (this.isBaseImprovementPlanPayUpfront) {
        if (this.isGiftQuiz) {
          return '/checkout-gift-receiver'
        } else {
          return '/checkout-b'
        }
      } else if (this.isBaseImprovementPlan) {
        if (this.isGiftQuiz) {
          return '/checkout-gift-receiver'
        } else {
          return '/checkout-payment'
        }
      } else {
        return '/checkout-payment'
      }
    },
    trackAddFastTrack () {
      this.trackGAEvent({
        action: 'fastTrackAdded',
        category: 'upsell',
        label: 'Fast Track added'
      })
    },
    addFastTrack () {
      this.trackAddFastTrack()
      this.$store.dispatch('product/selectFastTrack', this.mainArea)
    },
    closeModal () {
      this.$root.$emit('bv::hide::modal', 'fastTrackModal')
    }
  }
}
