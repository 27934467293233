//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import GetCouponData from '~/mixins/coupon/get-coupon-data'
import GAEcommerce from '~/mixins/ga-ecommerce'
import { GOAL_ID_TO_GOAL_KEY } from '@/constants/goalIdByType'

export default {
  mixins: [
    GetCouponData,
    GAEcommerce
  ],
  props: {
    showPromoCode: {
      type: Boolean,
      default: false
    },
    showQuarterlySelect: {
      type: Boolean,
      default: true
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    showEstimatedArrival: {
      type: Boolean,
      default: false
    },
    isB2bOrder: {
      type: Boolean,
      default: false
    },
    goalId: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      couponCodeValue: ''
    }
  },
  computed: {
    ...mapState('product', ['planSelected', 'areaPrice', 'mainArea', 'otherAreas', 'fastTrack', 'isGift', 'fastTrackTests', 'couponCode', 'starterTest', 'improvementProgramPrice', 'baseCompleteTests']),
    ...mapState('quiz', ['goalName']),
    ...mapGetters({
      getSubTotalPrice: 'product/getSubTotalPrice',
      getTotalPrice: 'product/getTotalPrice',
      getTotalPriceWithDiscount: 'product/getTotalPriceWithDiscount',
      getDiscount: 'product/getDiscount',
      getPlanPriceFull: 'product/getPlanPriceFull',
      getPlanPriceRaw: 'product/getPlanPriceRaw',
      getPlanPrice: 'product/getPlanPrice',
      isMonitorPlan: 'product/isMonitorPlan',
      isQuizPlanResults: 'product/isQuizPlanResults',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      isBaseCompletePlan: 'product/isBaseCompletePlan',
      isBaseCompleteResultsPlan: 'product/isBaseCompleteResultsPlan',
      isBaseCompleteImprovementPlan: 'product/isBaseCompleteImprovementPlan'
    }),
    tests () {
      return this.$t('general.orderSummaryB.body.quizPlanShipsAfter.included')
    },
    mainProductTitle () {
      return this.getAreaProductTitle(this.mainArea)
    },
    mainProductCaption () {
      const planSelected = this.isGift && (!this.isBaseCompleteResultsPlan && !this.isBaseCompletePlan && !this.isBaseCompleteImprovementPlan) ? 'gift' : this.planSelected
      return this.$t(`general.orderSummaryB.body.productSelectedCaption.${planSelected}`, { firstTest: this.starterTest })
    },
    productFastTrackTitle () {
      return this.$t(`general.orderSummaryB.body.productSelectedFastTrack.${this.planSelected}`, { area: this.mainArea })
    },
    showShipsAfterModule () {
      return !this.isGift && (this.mainArea || this.isBaseCompleteResultsPlan || this.isBaseCompleteImprovementPlan)
    },
    imageSingleItem () {
      let keyGoal = GOAL_ID_TO_GOAL_KEY[this.goalId]

      if (!keyGoal) {
        keyGoal = 'default'
      }

      return this.getStaticsObject(`/quiz-results/starter_test_box_${keyGoal}.png`)
    }
  },
  mounted () {
    this.applyRCCodeIfExists()
  },
  methods: {
    getAreaProductTitle (area) {
      let title = this.$t(`general.orderSummaryB.body.productSelected.${this.planSelected}`)
      if (this.isGift) {
        title = title.replace('monitor', ' Gift')
      }
      return title
    },
    getDateFromNow (monthFromNow, daysFromNow = 0) {
      let date = new Date()
      date.setMonth(date.getMonth() + monthFromNow)
      date.setDate(date.getDate() + daysFromNow)
      date = date.toDateString().substring(4, 10)
      return date
    },
    getFirstTest (area) {
      return this.$t(`general.testOptions.options.${area}`)[0].title
    },
    getNextPlanPrice () {
      if (this.isBaseCompleteImprovementPlan || this.isBaseImprovementPlan) {
        return 9.95
      }
      return this.isBaseCompleteResultsPlan ? this.getPlanPriceRaw('base-monitor') : this.getPlanPrice
    },
    removeMainArea () {
      this.addDDAction('Remove area button clicked', {
        path: this.$route.path,
        section: 'cart',
        area: this.mainArea
      })
      this.trackGAEcommerceRemoveFromCart({ area: this.mainArea, plan: this.planSelected })
      this.$store.dispatch('product/removeMainArea')
    },
    removeOtherArea (area) {
      this.addDDAction('Remove area button clicked', {
        path: this.$route.path,
        section: 'cart',
        area
      })
      this.trackGAEcommerceRemoveFromCart({ area, plan: this.planSelected })
      this.$store.dispatch('product/removeOtherArea', area)
    },
    openExpandedTestPopup () {
      this.$root.$emit('bv::show::modal', 'fastTrackModal')
    },
    async applyRCCodeIfExists () {
      const couponCode = sessionStorage.getItem('rc')
      if (couponCode) {
        const couponData = await this.getCouponData(couponCode)
        if (couponData.active) {
          this.$store.dispatch('product/setCouponCodeData', { ...couponData, name: couponCode })
        }
      }
    },
    async applyDiscount () {
      const couponCode = this.couponCodeValue.toUpperCase()
      const couponData = await this.getCouponData(couponCode)
      if (couponData) {
        this.$store.dispatch('product/setCouponCodeData', { ...couponData, name: couponCode })
      }
    }
  }
}
