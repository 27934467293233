var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"expanded-test mt-md-24 mt-sm-16 mx-auto",class:_vm.cssClasses,style:(_vm.cssVars)},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{
        'pr-16': _vm.integrated
      }},[_c('img',{staticClass:"expanded-test__image",attrs:{"src":_vm.imageExpanded,"alt":("Expanded test " + _vm.area),"loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column w-100",class:{
        'pt-24 pb-32': !_vm.integrated
      }},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"ff-swis721-md-bt pb-4",class:{
              'fs-md-20 fs-sm-14 lh-md-20 lh-sm-16 ls-0-5': !_vm.small,
              'fs-14 lh-16 ls-3': _vm.small,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('general.fastTrackB.title'))+"\n          ")])]),_vm._v(" "),(_vm.showPrices)?_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-end"},[_c('div',{staticClass:"ff-swis721-md-bt pb-4 pl-md-24 pl-sm-16",class:{
              'fs-md-20 fs-sm-14 lh-md-20 lh-sm-16 ls-0-5': !_vm.small,
              'fs-14 lh-16 ls-3': _vm.small,
            }},[(!_vm.isFastTrackSelected(_vm.area))?_c('span',[_vm._v("+")]):_vm._e(),_vm._v("$"+_vm._s(_vm.fastTrackPrice)+"\n          ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between position-relative"},[(_vm.showCollapsable)?_c('div',{staticClass:"col"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle"}],staticClass:"d-flex align-items-center text-decoration-none",attrs:{"href":"#expanded-tests"},on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"fs-sm-14 lh-sm-16 ls-0-5 c-grey pr-4",class:{
                'fs-md-16 lh-md-24': !_vm.small,
                'fs-md-14 lh-md-14': _vm.small,
              },domProps:{"innerHTML":_vm._s(_vm.$t('general.fastTrackB.numTests', {num: _vm.fastTrackTests.length}))}}),_vm._v(" "),_c('b-icon-chevron-down',{staticStyle:{"color":"#00000066"},attrs:{"scale":"0.8"}})],1),_vm._v(" "),_c('b-collapse',{staticClass:"pt-16 pb-sm-8",attrs:{"id":"expanded-tests","accordion":"expanded-tests-accordion","role":"tabpanel"}},_vm._l((_vm.fastTrackTests),function(test){return _c('div',{key:test,staticClass:"d-flex"},[_c('b-icon-check2',{staticStyle:{"color":"#4B9EFF"}}),_vm._v(" "),_c('div',{staticClass:"c-grey pl-8 pb-8",class:{
                  'fs-md-16 fs-sm-12 lh-md-16 lh-sm-12 ls-0-5': !_vm.small,
                  'fs-14 lh-16 ls-3': _vm.small,
                }},[_vm._v("\n                "+_vm._s(test)+"\n              ")])],1)}),0),_vm._v(" "),(!_vm.integrated & _vm.showPrices)?_c('div',{staticClass:"fs-sm-14 lh-sm-16 ls-3 c-grey",class:{
              'fs-md-16 lh-md-24': !_vm.small,
              'fs-md-14 lh-md-20': _vm.small,
            },domProps:{"innerHTML":_vm._s(_vm.$t('general.fastTrackB.caption'))}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"expanded-test__button",class:{
              'pt-md-16': !_vm.integrated
            }},[(!_vm.isFastTrackSelected(_vm.area) && _vm.showRemoveButton)?_c('base-button',{attrs:{"text":_vm.$t('general.fastTrackB.addButtonB'),"fluid":true,"prefetch":false,"theme":"dark","size":"sm","font":"md"},on:{"click":_vm.addFastTrack}}):(_vm.showRemoveButton)?_c('div',{staticClass:"fs-14 lh-md-20 lh-sm-16 ls-4 c-grey text-end text-decoration-underline cursor-pointer",on:{"click":_vm.removeFastTrack}},[_vm._v("\n              "+_vm._s(_vm.$t('general.fastTrackB.removeButtonB'))+"\n            ")]):_vm._e()],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }