import { DD_RUM_USER_COOKIE } from '~/constants/cookies'

export default {
  async mounted () {
    this.checkCouponCode()
    this.identifyRUMUser()
    this.trackAdId()
    await this.saveFBAdsData()
    this.saveUtmParamsIfExists()
  },
  methods: {
    createSeoTags ({ title, description, url, link = [], canonical = null }) {
      return {
        title,
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { hid: 'description', name: 'description', content: description },
          { property: 'og:type', content: 'website', vmid: 'og:type' },
          { property: 'og:description', content: description, vmid: 'og:description' },
          { property: 'og:title', content: title, vmid: 'og:title' },
          { property: 'og:url', content: url, vmid: 'og:url' },
          { property: 'og:image', content: 'https://statics.get-base.com/boxes.jpg', vmid: 'og:image' }
        ],
        link: [
          { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
          { rel: 'canonical', href: canonical || url },
          ...link
        ]
      }
    },
    identifyRUMUser () {
      if (window.DD_RUM && window.gtag) {
        window.gtag('get', 'UA-150539018-2', 'client_id', (gaClientId) => {
          const { $score: score = null, name = null, email = null } = this.$route.query
          const userData = {
            gaId: gaClientId
          }
          if (score && name) {
            userData.score = score
            userData.name = name
          }
          if (email) {
            userData.email = email
          }
          const ddRumUser = this.$cookies.get(DD_RUM_USER_COOKIE)
          if (!ddRumUser || !ddRumUser.name) {
            this.$cookies.set(DD_RUM_USER_COOKIE, userData)
            window.DD_RUM.setUser(userData)
          }
        })
      }
    },
    checkCouponCode () {
      const code = this.$route.query.rc
      if (code) {
        sessionStorage.setItem('rc', code)
      }
    },
    trackAdId () {
      const adId = this.$route.query.utm_adid
      if (adId) {
        this.trackGAEvent({
          action: 'ad_id_dimension',
          extraProperties: {
            ad_id: adId
          },
          delay: true
        })
      }
    },
    async saveFBAdsData () {
      const fbclid = this.$route.query.fbclid
      if (fbclid) {
        const currentTime = Date.now()
        await this.$store.dispatch('fb/setFBClid', fbclid)
        await this.$store.dispatch('fb/setFBClidCreationTime', currentTime)
      }
    },
    saveUtmParamsIfExists () {
      const { utm_source: utmSource = '', utm_medium: utmMedium = '', utm_campaign: utmCampaign = '' } = this.$route.query
      if (utmSource) {
        sessionStorage.setItem('utmSource', utmSource)
      }
      if (utmMedium) {
        sessionStorage.setItem('utmMedium', utmMedium)
      }
      if (utmCampaign) {
        sessionStorage.setItem('utmCampaign', utmCampaign)
      }
    }
  }
}
