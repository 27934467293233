export default {
  methods: {
    async getData (email = null) {
      const params = new URLSearchParams()
      if (email) {
        params.append('email', email)
      }
      try {
        return await this.$axios.$get('/web/areas/menu', { params })
      } catch (e) {
      }
    }
  }
}
