export default {
  addOtherArea (state, area) {
    state.otherAreas.push(area)
  },
  addTestForGoalID (state, testForGoalID) {
    state.testForGoalIDs.push(testForGoalID)
  },
  setPlanSelected (state, plan) {
    state.planSelected = plan
  },
  setMainArea (state, area) {
    state.mainArea = area
  },
  setAreaToAdd (state, area) {
    state.areaToAdd = area
  },
  setPlanToAdd (state, plan) {
    state.planToAdd = plan
  },
  setStarterTestToAdd (state, starterTestToAdd) {
    state.starterTestToAdd = starterTestToAdd
  },
  setStarterTestIdToAdd (state, starterTestIdToAdd) {
    state.starterTestIdToAdd = starterTestIdToAdd
  },
  setOtherAreas (state, otherAreas) {
    state.otherAreas = otherAreas
  },
  setFastTrack (state, fastTrack) {
    state.fastTrack = fastTrack
  },
  setPayNowPlan (state, payNowPlan) {
    state.payNow.plan = payNowPlan
  },
  setPayNowArea (state, payNowArea) {
    state.payNow.area = payNowArea
  },
  setIsGift (state, isGift) {
    state.isGift = isGift
  },
  setFasTrackTests (state, fastTrackTests) {
    state.fastTrackTests = fastTrackTests
  },
  setFastTrackTestsPrice (state, { area, price }) {
    state.fastTrackPrices[area] = price
  },
  // TODO: this should not come from server unless we are A/B testing. We are experiencing delays.
  setPlanPrice (state, price) {
    state.planPrice['base-quiz'] = price
    state.planPrice['base-quiz-results'] = price
    state.planPrice['base-improvement-plan'] = price
    state.planPrice['base-monitor'] = price
    state.planPrice['base-improvement-plan-pay-upfront'] = price
  },
  setBaseCompletePrice (state, price) {
    state.planPrice['base-complete'] = price
    state.planPrice['base-complete-results'] = price
    state.planPrice['base-complete-improvement-plan'] = price
  },
  setCouponCode (state, couponCodeData) {
    const {
      couponCode
    } = state
    couponCode.name = couponCodeData.name
    couponCode.type = couponCodeData.amountOff > 0 ? 'AMOUNT_OFF' : 'PERCENTAGE_DISCOUNT'
    couponCode.value = couponCodeData.amountOff > 0 ? couponCodeData.amountOff : couponCodeData.percentageDiscount
    couponCode.active = couponCodeData.active
  },
  setStarterTest (state, starterTest) {
    state.starterTest = starterTest
  },
  setStarterTestId (state, starterTestId) {
    state.starterTestId = starterTestId
  },
  setFastTrackTestsAreas (state, fastTrackTestsAreas) {
    state.fastTrackTestsAreas = fastTrackTestsAreas
  },
  setPaidWithPayNowInArea (state, paidWithPayNowInArea) {
    state.paidWithPayNowInArea = paidWithPayNowInArea
  },
  setTestMenu (state, testMenu) {
    state.testMenu = testMenu
  },
  removeMainArea (state) {
    const { otherAreas } = state
    state.mainArea = otherAreas.length > 0 ? otherAreas[0] : null
    state.otherAreas = otherAreas.filter(otherArea => otherArea !== state.mainArea)
    state.testForGoalIDs = state.testForGoalIDs.shift()
  },
  removeOtherArea (state, area) {
    const testForGoalIDToRemoveIndex = state.otherAreas.findIndex(otherArea => otherArea === area)
    state.otherAreas = state.otherAreas.filter(otherArea => otherArea !== area)
    state.testForGoalIDs.splice(testForGoalIDToRemoveIndex, 1)
  },
  setBaseCompleteTests (state, tests) {
    state.baseCompleteTests = tests
  },
  setTestForGoalIDs (state, testForGoalIDs) {
    state.testForGoalIDs = testForGoalIDs
  },
  setImprovementProgramPrice (state, improvementProgramPrice) {
    state.improvementProgramPrice = improvementProgramPrice
  }
}
