//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  computed: {
    links () {
      return this.$t('practitioners.navbar.links')
    }
  },
  methods: {
    trackLink (link) {
      const from = this.$route.path
      this.addDDAction('Nav bar link clicked', {
        link,
        path: from
      })
      this.trackGAEvent({
        action: 'click navbar link',
        extraProperties: {
          to: link,
          from
        }
      })
    },
    scrollTo () {
      this.trackLink('/providers')
      this.$router.push('/providers/home')
      setTimeout(() => document.getElementById('contact-form').scrollIntoView(), 500)
    }
  }
}
