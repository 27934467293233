export default {
  methods: {
    existsIntercomWidget () {
      return Object.prototype.hasOwnProperty.call(window, 'Intercom')
    },
    updateIntercomSettings (settings = {}) {
      if (this.existsIntercomWidget()) {
        window.Intercom('update', settings)
      }
    },
    shutdownIntercomWidget () {
      if (this.existsIntercomWidget()) {
        window.Intercom('shutdown')
      }
    },
    openIntercomWidget () {
      if (this.existsIntercomWidget()) {
        window.Intercom('show')
      }
    }
  }
}
