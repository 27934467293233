import Vue from 'vue'
import * as typeformEmbed from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

Vue.component('Typeform', {
  props: {
    formId: {
      type: String,
      required: true
    },
    containerId: {
      type: String,
      default: 'form'
    }
  },
  mounted () {
    typeformEmbed.createWidget(this.formId, {
      container: document.querySelector(`#${this.containerId}`),
      inlineOnMobile: true
    })
  },
  render (createElement) {
    return createElement('div')
  }
})
