import { mapGetters, mapState } from 'vuex'
import StripeMixin from '../stripe'

export default {
  mixins: [StripeMixin],
  data () {
    return {
      paynowPaymentRequest: null,
      totalPrice: 0,
      showPayNowButton: true,
      defaultButtonsId: ['#payment-request-button']
    }
  },
  computed: {
    ...mapState('product', ['couponCode']),
    ...mapGetters({
      getPayNowPlan: 'product/getPayNowPlan',
      getPayNowArea: 'product/getPayNowArea',
      isQuizPayNowPlan: 'product/isQuizPayNowPlan',
      isMonitorPayNowPlan: 'product/isMonitorPayNowPlan'
    })
  },
  watch: {
    mainArea: {
      immediate: true,
      async handler (area) {
        if (this.isStripeDisabled) {
          return
        }
        if (!area && this.cartSidebarOpen) {
          this.showPayNowButton = false
        }
        this.totalPrice = this.getPayNowPrice()
        if (!this.paynowPaymentRequest) {
          this.paynowPaymentRequest = this.createPaymentRequest()
          this.onPaymentDone()
        }
        if ((!this.cartSidebarOpen && this.getTotalPayNowPrice > 0) ||
          (area && !this.showPayNowButton && this.getTotalPrice > 0)) {
          await this.createPayNowButton()
        }
        this.updatePaymentRequest()
      }
    },
    async cartSidebarOpen (open) {
      if (this.isStripeDisabled) {
        return
      } else if (open && this.getTotalPrice === 0) {
        this.showPayNowButton = false
      } else if (!open && this.getTotalPayNowPrice > 0) {
        await this.createPayNowButton()
      }
      this.updatePayment()
    },
    async showFloatingButton () {
      await this.createPayNowButton()
    },
    otherAreas () {
      this.updatePayment()
    },
    fastTrack () {
      this.updatePayment()
    },
    couponCodeName () {
      this.updatePayment()
    }
  },
  methods: {
    createPaymentMethodButton () {
      const elements = this.$stripe.elements()
      return elements.create('paymentRequestButton', {
        paymentRequest: this.paynowPaymentRequest,
        style: {
          paymentRequestButton: {
            height: this.$mq === 'sm' ? '47px' : '50px'
          }
        }
      })
    },
    async createPayNowButton () {
      const result = await this.paynowPaymentRequest.canMakePayment()
      if (result) {
        this.showPayNowButton = true
        this.$nextTick(() => {
          const buttonsIds = this.payNowButtonsId || this.defaultButtonsId
          buttonsIds.forEach((id) => {
            const existsButton = document.getElementById(id.substr(1))
            if (existsButton) {
              const payNowButton = this.createPaymentMethodButton()
              payNowButton.mount(id)
            }
          })
        })
      }
    },
    createPaymentRequest () {
      return this.$stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: this.totalPrice
        },
        requestPayerName: true,
        requestPayerEmail: true
      })
    },
    updatePayment () {
      this.totalPrice = this.getPayNowPrice()
      this.updatePaymentRequest()
    },
    updatePaymentRequest () {
      if (this.isStripeDisabled) {
        return
      }
      this.paynowPaymentRequest.update({
        total: {
          label: 'Total',
          amount: this.totalPrice
        }
      })
    },
    onPaymentDone () {
      this.paynowPaymentRequest.on('paymentmethod', async event => await this.createPayNowPayment({ event }))
    }
  }
}
