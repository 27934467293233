//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from '~/mixins/page'

export default {
  mixins: [PageMixin],
  data () {
    return {
      showFloatingButton: false
    }
  },
  head () {
    return this.createSeoTags({
      title: this.$t('error.seo.title'),
      description: this.$t('error.seo.description'),
      url: 'https://get-base.com/error'
    })
  }
}
