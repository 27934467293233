export default {
  setGoalId ({ commit }, goalId) {
    commit('setGoalId', goalId)
  },
  setEmail ({ commit }, email) {
    commit('setEmail', email)
  },
  setScore ({ commit }, score) {
    commit('setScore', score)
  },
  setGoalName ({ commit }, goalName) {
    commit('setGoalName', goalName)
  },
  setName ({ commit }, name) {
    commit('setName', name)
  },
  resetQuizData ({ commit }) {
    commit('setGoalId', -1)
    commit('setEmail', '')
  }
}
