import { render, staticRenderFns } from "./cart-sidebar.vue?vue&type=template&id=fc14df34&"
import script from "./cart-sidebar.vue?vue&type=script&lang=js&"
export * from "./cart-sidebar.vue?vue&type=script&lang=js&"
import style0 from "./cart-sidebar.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseOrderSummaryC: require('/home/runner/work/webpage/webpage/components/base/order-summary-c/order-summary-c.vue').default,BaseButton: require('/home/runner/work/webpage/webpage/components/base/button/button.vue').default})
