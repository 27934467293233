export default {
  getDiscount: (state, getters) => {
    const {
      mainArea,
      couponCode
    } = state
    if (!mainArea || !couponCode.value) {
      return 0
    }
    if (couponCode.type === 'AMOUNT_OFF') {
      return couponCode.value
    }
    return ((getters.getSubTotalPrice) * couponCode.value) / 100
  },
  getTotalPriceWithDiscount: (state, getters) => {
    return getters.getTotalPrice - getters.getDiscount
  },
  getSubTotalPrice: (state, getters) => {
    const {
      planSelected,
      planPrice,
      areaPrice,
      mainArea,
      otherAreas,
      improvementProgramPrice
    } = state
    if (!mainArea) {
      return 0
    }
    let totalPlanPrice = planPrice[planSelected]
    if (getters.isBaseImprovementPlanPayUpfront) {
      totalPlanPrice += improvementProgramPrice
    }

    const totalAreaPrice = (otherAreas.length * areaPrice)
    return Math.round((totalPlanPrice + totalAreaPrice + getters.getFastTrackPrice) * 100) / 100
  },
  getTotalPrice: (state, getters) => {
    const totalPrice = getters.getSubTotalPrice - getters.getDiscount
    return Math.round(totalPrice * 100) / 100
  },
  getTotalPayNowPrice: (state, getters) => {
    const {
      planPrice
    } = state
    return getters.getPayNowPlan ? planPrice[getters.getPayNowPlan] : 0
  },
  getPriceWithoutImprovementProgram: (state, getters) => {
    const {
      planPrice,
      planSelected
    } = state

    return planPrice[planSelected]
  },
  getPlanPriceFull: (state, getters) => state.planPrice[state.planSelected] + getters.getFastTrackPrice,
  getPlanPrice: state => state.planPrice[state.planSelected],
  getPlanPriceRaw: state => planId => state.planPrice[planId],
  getFoodTestPriceRaw: state => '175.00',
  getFoodSensitivityTestPriceRaw: state => '184.00',
  getNutritionalPlanPriceRaw: state => '49.95',
  getBaseDailyPriceRaw: state => '9.95',
  getAreaFastTrackTests: state => area => state.fastTrackTestsAreas[area],
  getFastTrackPrice: state => state.fastTrack ? state.fastTrackPrices[state.fastTrack] : 0,
  getTotalProducts: state => !state.mainArea ? 0 : 1 + state.otherAreas.length,
  getImprovementProgramPrice: state => state.improvementProgramPrice,
  getImprovementPlanPlusProgramPrice: state => state.planPrice['base-improvement-plan'] + state.improvementProgramPrice,
  getBaseCompletePlusProgramPrice: state => state.planPrice['base-complete-improvement-plan'] + state.improvementProgramPrice,
  getPayNowPlan: state => state.payNow.plan,
  getPayNowArea: state => state.payNow.area,
  getTestMenuData: state => ({ area, starterTestId }) => state.testMenu && state.testMenu[area].find(test => test.testForGoalID === starterTestId),
  isAreaSelected: state => area => state.mainArea === area || state.otherAreas.includes(area),
  isFastTrackSelected: state => fastTrack => state.fastTrack === fastTrack,
  isQuizPlanResults: state => state.planSelected === 'base-quiz-results',
  isBaseImprovementPlan: state => state.planSelected === 'base-improvement-plan',
  isBaseImprovementPlanPayUpfront: state => state.planSelected === 'base-improvement-plan-pay-upfront',
  isQuizPlan: state => state.planSelected === 'base-quiz',
  isQuizPayNowPlan: (state, getters) => getters.getPayNowPlan === 'base-quiz-results' || getters.getPayNowPlan === 'base-improvement-plan',
  isMonitorPayNowPlan: (state, getters) => getters.getPayNowPlan === 'base-monitor',
  isMonitorPlan: state => state.planSelected === 'base-monitor',
  isBaseCompletePlan: state => state.planSelected === 'base-complete',
  isBaseCompleteResultsPlan: state => state.planSelected === 'base-complete-results',
  isBaseCompleteImprovementPlan: state => state.planSelected === 'base-complete-improvement-plan',
  isBaseCompleteImprovementPlanPayUpfront: state => state.planSelected === 'base-complete-improvement-plan-pay-upfront'
}
