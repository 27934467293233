//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Whether to show the links in the nav bar
     */
    showLinks: {
      type: Boolean,
      default: true
    },
    /**
     * Whether the logo is clickable
     */
    logoLink: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to show the business page learn more button
     */
    showBusinessContact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      score: this.$route.query.$score,
      area: null,
      emptyCart: true
    }
  },
  computed: {
    ...mapState('product', ['mainArea']),
    ...mapState('quiz', ['email']),
    ...mapGetters({
      getTotalProducts: 'product/getTotalProducts',
      isFastTrackSelected: 'product/isFastTrackSelected',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      getQuizResultsQueryParams: 'quiz/getQuizResultsQueryParams'
    }),
    showContinueButton () {
      return ['/quiz-results', '/quiz-results-b', '/quiz-results-c', '/quiz-results-d'].includes(this.$route.path)
    },
    showCart () {
      return ['/gifts', '/all-goals', '/affiliate/fixmydiet', '/goal-gift'].includes(this.$route.path)
    },
    isPractitionersPage () {
      return this.$route.path.includes('/practitioners')
    },
    isBusinessPage () {
      return this.$route.path.includes('/business', '/whitepaper')
    },
    showQuizResultsLink () {
      return !['/quiz-results', '/quiz-results-c', '/quiz-results-d', '/goal-gift', 'all-goals'].includes(this.$route.path) && this.isBaseImprovementPlan && this.email
    },
    showLinksOverride () {
      return this.showLinks && !this.$route.path.includes('/food-allergy') && !this.$route.path.includes('/food-sensitivity') && !this.$route.path.includes('/nutritional-plan') && !this.$route.path.includes('/new-password')
    },
    isGoalPage () {
      const goalPaths = [
        '/goal/weight-management',
        '/goal/libido',
        '/goal/fatigue',
        '/goal/brain-fog',
        '/goal/anxiety-depression',
        '/goal/heart-health',
        '/goal/womens-health',
        '/goal/longevity',
        '/goal/bloating',
        '/goal/menopause',
        '/goal/hormonal-imbalances',
        '/goal/digestive-issues'
      ]

      return goalPaths.includes(this.$route.path) || this.$route.path.includes('/test/')
    },
    showQuizButton () {
      return !this.showQuizResultsLink &&
        (['/terms', '/privacy-policy', '/reviews', '/complete-testing-plan', '/science', '/how-it-works', '/food-allergy', '/food-sensitivity', '/nutritional-plan'].includes(this.$route.path) || this.$route.path === '/' || this.isGoalPage)
    },
    showBusinessLinks () {
      return !this.isGoalPage && !this.$route.path.includes('/food-allergy', '/nutritional-plan', '/quiz-results')
    },
    dropdownLinks () {
      return this.$t('general.navBar.dropdown.links')
    },
    links () {
      return this.showBusinessLinks ? this.$t('general.navBar.links') : this.$t('general.navBar.linksNoBusiness')
    }
  },
  watch: {
    mainArea (value) {
      this.emptyCart = !value
    }
  },
  mounted () {
    this.emptyCart = !this.mainArea
    const areaLogic = new Map([
      ['1', 'stress'],
      ['10', 'stress'],
      ['2', 'sleep'],
      ['20', 'sleep'],
      ['3', 'energy'],
      ['30', 'energy'],
      ['4', 'diet'],
      ['40', 'diet'],
      ['5', 'sex-drive'],
      ['50', 'sex-drive']
    ])
    this.area = areaLogic.get(this.score)
  },
  methods: {
    trackLink (link) {
      const from = this.$route.path
      this.addDDAction('Nav bar link clicked', {
        link,
        path: from
      })
      this.trackGAEvent({
        action: 'click navbar link',
        extraProperties: {
          to: link,
          from
        }
      })
    },
    openFastTrackAndCheckout () {
      this.$root.$emit('bv::show::modal', 'fastTrackModal')
    },
    scrollTo () {
      this.trackLink('/practitioners')
      location.hash = ''
      location.hash = '#contact-form'
    },
    onHover (dropdown) {
      this.$refs[dropdown].visible = true
    },
    onLeave (dropdown) {
      this.$refs[dropdown].visible = false
    }
  }
}
