//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Whether to show the links in the nav bar
     */
    showLinks: {
      type: Boolean,
      default: true
    },
    /**
     * Whether the logo is clickable
     */
    logoLink: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to show the business page learn more button
     */
    showBusinessContact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      emptyCart: true
    }
  },
  computed: {
    ...mapState('product', ['mainArea']),
    ...mapState('quiz', ['email']),
    ...mapGetters({
      getPlanPrice: 'product/getPlanPrice',
      getTotalProducts: 'product/getTotalProducts',
      isFastTrackSelected: 'product/isFastTrackSelected',
      isBaseImprovementPlan: 'product/isBaseImprovementPlan',
      getQuizResultsQueryParams: 'quiz/getQuizResultsQueryParams'
    }),
    showContinueButton () {
      return ['/quiz-results', '/quiz-results-b', '/quiz-results-c'].includes(this.$route.path)
    },
    showCart () {
      return ['/gifts', '/all-goals', '/affiliate/fixmydiet', '/goal-gift'].includes(this.$route.path)
    },
    isPractitionersPage () {
      return this.$route.path.includes('/practitioners')
    },
    showQuizResultsLink () {
      return !['/quiz-results', '/quiz-results-c', '/goal-gift'].includes(this.$route.path) && this.isBaseImprovementPlan && this.email
    },
    isGoalPage () {
      return ['/goal/weight-management', '/goal/libido', '/goal/fatigue', '/goal/brain-fog', '/goal/anxiety-depression', '/goal/heart-health', '/goal/womens-health', '/goal/longevity', '/goal/bloating'].includes(this.$route.path)
    },
    showQuizButton () {
      return !this.showQuizResultsLink &&
        (['/terms', '/privacy-policy', '/reviews', '/complete-testing-plan', '/science', '/how-it-works'].includes(this.$route.path) || this.$route.path === '/' || this.isGoalPage)
    },
    dropdownLinks () {
      return this.$t('general.navBar.dropdown.links')
    },
    dropdownLearnLinks () {
      return this.$t('general.navBar.dropdownLearn.links')
    },
    links () {
      return this.$t('general.navBar.links')
    }
  },
  watch: {
    mainArea (value) {
      this.emptyCart = !value
    }
  },
  mounted () {
    this.emptyCart = !this.mainArea
  },
  methods: {
    trackLink (link) {
      const from = this.$route.path
      this.addDDAction('Nav bar link clicked', {
        link,
        path: from
      })
      this.trackGAEvent({
        action: 'click navbar link',
        extraProperties: {
          to: link,
          from
        }
      })
    },
    openFastTrackAndCheckout () {
      this.$root.$emit('bv::show::modal', 'fastTrackModal')
    },
    scrollTo () {
      this.trackLink('/practitioners')
      location.hash = ''
      location.hash = '#contact-form'
    },
    onHover (dropdown) {
      this.$refs[dropdown].visible = true
    },
    onLeave (dropdown) {
      this.$refs[dropdown].visible = false
    }
  }
}
