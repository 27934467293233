export default {
  initProduct ({ dispatch }) {
    dispatch('setIsGift', false)
  },
  selectPlan ({ commit }, plan) {
    commit('setPlanSelected', plan)
  },
  selectMainArea ({ commit, state, dispatch }, area) {
    const { mainArea } = state
    if (mainArea === area) {
      return
    }
    if (!mainArea) {
      commit('setMainArea', area)
      return
    }
    dispatch('selectOtherAreas', area)
  },
  selectAreaToAdd ({ commit }, area) {
    commit('setAreaToAdd', area)
  },
  selectPlanToAdd ({ commit }, plan) {
    commit('setPlanToAdd', plan)
  },
  selectStarterTestToAdd ({ commit }, starterTestToAdd) {
    commit('setStarterTestToAdd', starterTestToAdd)
  },
  selectStarterTestIdToAdd ({ commit }, starterTestIdToAdd) {
    commit('setStarterTestIdToAdd', starterTestIdToAdd)
  },
  selectOtherAreas ({ commit, state }, area) {
    const { otherAreas } = state
    if (otherAreas.includes(area)) {
      return
    }
    commit('addOtherArea', area)
  },
  selectStarterTest ({ commit }, starterTest) {
    commit('setStarterTest', starterTest)
  },
  setStarterTestId ({ commit }, starterTestId) {
    commit('setStarterTestId', starterTestId)
  },
  selectFastTrack ({ commit }, fastTrack) {
    commit('setFastTrack', fastTrack)
  },
  selectMonitorArea ({ dispatch, getters, state, commit }, area) {
    if (getters.isBaseCompleteResultsPlan) {
      dispatch('resetMainArea')
    }
    dispatch('selectPlan', 'base-monitor')
    dispatch('selectMainArea', area)
    if (state.fastTrackTestsAreas) {
      const fastTrackTests = getters.getAreaFastTrackTests(area)
      dispatch('setFasTrackTests', fastTrackTests)
    }
    commit('payment/setPaymentType', 'test', { root: true })
  },
  selectBaseCompleteResultsPlan ({ dispatch, commit }) {
    dispatch('selectPlan', 'base-complete-results')
    dispatch('resetMainArea')
    dispatch('selectMainArea', 'diet')
    dispatch('resetFastTrack')
    dispatch('resetOtherAreas')
    dispatch('resetTestForGoalIDs')
    commit('payment/setPaymentType', 'subscription', { root: true })
  },
  selectBaseCompleteImprovementPlan ({ dispatch, commit }) {
    dispatch('selectPlan', 'base-complete-improvement-plan')
    dispatch('resetMainArea')
    dispatch('selectMainArea', 'diet')
    dispatch('resetOtherAreas')
    dispatch('resetFastTrack')
    dispatch('resetTestForGoalIDs')
    commit('payment/setPaymentType', 'subscription', { root: true })
  },
  selectBaseCompleteImprovementPlanPayUpfront ({ dispatch, commit }) {
    dispatch('selectPlan', 'base-complete-improvement-plan-pay-upfront')
    dispatch('resetMainArea')
    dispatch('selectMainArea', 'diet')
    dispatch('resetOtherAreas')
    dispatch('resetTestForGoalIDs')
    commit('payment/setPaymentType', 'subscription', { root: true })
  },
  selectBaseCompletePlan ({ dispatch }) {
    dispatch('selectPlan', 'base-complete')
    dispatch('resetMainArea')
    dispatch('selectMainArea', 'diet')
    dispatch('resetOtherAreas')
    dispatch('resetTestForGoalIDs')
  },
  setIsGift ({ commit }, isGift) {
    commit('setIsGift', isGift)
  },
  setFasTrackTests ({ commit }, fastTrackTests) {
    commit('setFasTrackTests', fastTrackTests)
  },
  setBaseCompletePrice ({ commit }, price) {
    commit('setBaseCompletePrice', price)
  },
  setPaidWithPayNowInArea ({ commit }, paidWithPayNowInArea) {
    commit('setPaidWithPayNowInArea', paidWithPayNowInArea)
  },
  removeArea ({ dispatch, state }, area) {
    const { mainArea } = state
    if (mainArea === area) {
      dispatch('resetFastTrack')
      return dispatch('removeMainArea')
    }
    dispatch('removeOtherArea', area)
  },
  removeMainArea ({ commit, state, dispatch }) {
    commit('removeMainArea')
    const { otherAreas, mainArea } = state
    if (otherAreas.length > 0) {
      const newMainArea = otherAreas.shift()
      dispatch('selectMainArea', newMainArea)
    }
    if (!mainArea) {
      dispatch('selectPlan', null)
    }
    dispatch('resetFastTrack')
    dispatch('resetStarterTestId')
    dispatch('resetStarterTest')
  },
  removeOtherArea ({ commit, state }, otherArea) {
    commit('removeOtherArea', otherArea)
  },
  removeBaseComplete ({ dispatch }) {
    dispatch('selectPlan', null)
    dispatch('resetMainArea')
  },
  resetOtherAreas ({ commit }) {
    commit('setOtherAreas', [])
  },
  resetPlan ({ dispatch }) {
    dispatch('selectPlan', null)
  },
  resetMainArea ({ commit }) {
    commit('setMainArea', null)
  },
  resetFastTrack ({ commit }) {
    commit('setFastTrack', null)
  },
  resetStarterTest ({ commit }) {
    commit('setStarterTest', null)
  },
  resetStarterTestId ({ commit }) {
    commit('setStarterTestId', -1)
  },
  resetTestForGoalIDs ({ commit }) {
    commit('setTestForGoalIDs', [])
  },
  resetProducts ({ dispatch }) {
    dispatch('resetPlan')
    dispatch('resetMainArea')
    dispatch('resetOtherAreas')
    dispatch('resetFastTrack')
    dispatch('resetStarterTest')
    dispatch('resetStarterTestId')
    dispatch('resetTestForGoalIDs')
  },
  setCouponCodeData ({ commit }, couponCodeData) {
    commit('setCouponCode', couponCodeData)
    sessionStorage.setItem('code', couponCodeData.name)
  },
  setBaseCompleteTests ({ commit }, tests) {
    commit('setBaseCompleteTests', tests)
  },
  addTestForGoalID ({ commit }, testForGoalID) {
    commit('addTestForGoalID', testForGoalID)
  }
}
