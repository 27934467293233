//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import GAEcommerce from '~/mixins/ga-ecommerce'

export default {
  mixins: [GAEcommerce],
  computed: {
    ...mapState('product', ['areaToAdd', 'planToAdd', 'planSelected', 'starterTestToAdd', 'starterTestIdToAdd']),
    subtitle () {
      return this.planToAdd ? this.$t(`general.removeQuizPlanModal.subtitle.${this.planToAdd}`) : ''
    },
    testToAdd () {
      return this.planToAdd && this.areaToAdd
        ? this.$t(`general.removeQuizPlanModal.test.adding.${this.planToAdd}`, { area: this.areaToAdd })
        : ''
    },
    isPayUpfrontActiveAb () {
      return this.isABActivated('pay-upfront-project')
    }
  },
  methods: {
    closeModal () {
      this.$root.$emit('bv::hide::modal', 'removeQuizPlanModal')
    },
    trackAddToCartMonitor () {
      this.trackFBPixelEvent({
        event: 'AddToCart',
        properties: {
          plan: 'base-monitor',
          area: this.areaToAdd
        }
      })
      this.trackGAEcommerceAddToCart({ area: this.areaToAdd })
    },
    trackAddToCartComplete ({ plan }) {
      this.trackFBPixelEvent({
        event: 'AddToCart',
        properties: {
          plan
        }
      })
      this.trackGAEcommerceAddToCart({ plan })
    },
    removeQuizAndAddPlan () {
      this.$store.dispatch('product/resetMainArea')
      if (this.planToAdd === 'base-monitor') {
        this.trackAddToCartMonitor()
        const starterTest = this.starterTestToAdd || this.$t(`general.testOptions.options.${this.areaToAdd}`)[0].title
        if (this.starterTestIdToAdd) {
          this.$store.dispatch('product/setStarterTestId', this.starterTestIdToAdd)
        }
        this.$store.dispatch('product/selectStarterTest', starterTest)
        this.$store.dispatch('product/selectMonitorArea', this.areaToAdd)
      } else if (this.planToAdd === 'base-complete-results') {
        this.trackAddToCartComplete({ plan: 'base-complete-results' })
        this.$store.dispatch('product/selectBaseCompleteResultsPlan')
      } else if (this.planToAdd === 'base-complete-improvement-plan') {
        this.trackAddToCartComplete({ plan: 'base-complete-improvement-plan' })
        this.$store.dispatch('product/selectBaseCompleteImprovementPlan')
      } else if (this.isPayUpfrontActiveAb) {
        this.trackAddToCartComplete({ plan: 'base-complete-improvement-plan-pay-upfront' })
        this.$store.dispatch('product/selectBaseCompleteImprovementPlanPayUpfront')
      }
      this.$store.dispatch('product/selectAreaToAdd', null)
      this.$store.dispatch('product/resetFastTrack')
      this.closeModal()
    }
  }
}
