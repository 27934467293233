//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      area: this.$route.params.area,
      showLearMoreButton: this.$route.path === '/business',
      learMoreButtonHref: '/business#contact-form',
      code: this.$route.params.b2b,
      isB2b: this.$route.path.includes('b2b'),
      b2b: this.$route.params.b2b
    }
  },
  computed: {
    bannerText () {
      return this.$t(`b2b.banner.${this.b2b}`)
    }
  }
}
