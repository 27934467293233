//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      area: this.$route.params.area
    }
  }
}
