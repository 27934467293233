export default {
  setGoalId (state, goalId) {
    state.goalId = goalId
  },
  setGoalName (state, goalName) {
    state.goalName = goalName
  },
  setScore (state, score) {
    state.score = score
  },
  setName (state, name) {
    state.name = name
  },
  setEmail (state, email) {
    state.email = email
  }
}
