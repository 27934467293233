import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    modules: [
      'product',
      'quiz',
      'user',
      'fb',
      'payment'
    ]
  }).plugin(store)
}
