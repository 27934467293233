export default () => {
  (function (i, s, o, g, r, a, m) {
    a = s.createElement(o)
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://googletagmanager.com/gtag/js?id=UA-150539018-2', 'gtag')
  const isTrafficExcluded = Boolean(localStorage.getItem('excludeGATraffic'))
  if (!isTrafficExcluded) {
    window.dataLayer = window.dataLayer || []
    function gtag () { window.dataLayer.push(arguments) }
    window.gtag = gtag
    gtag('js', new Date())

    // send the first page hit after you are assigned the experiment
    setTimeout(function () {
      gtag('config', 'UA-150539018-2', {
        optimize_id: 'OPT-NQ489C4',
        custom_map: {
          dimension1: 'ad_id'
        }
      })
    }, 1000)
  }
}
