import Vue from 'vue'

Vue.mixin({
  methods: {
    isMobile () {
      return this.$mq === 'sm'
    },
    isDesktop () {
      return this.$mq === 'lg'
    },
    isTablet () {
      return this.$mq === 'md'
    },
    trackFBPixelEvent ({ event, properties = {}, extraProperties = {} }) {
      if (window.fbq) {
        window.fbq('track', event, properties, extraProperties)
      }
    },
    trackTikTokEvent ({ event, properties = {} }) {
      if (window.ttq) {
        window.ttq.track(event, properties)
      }
    },
    trackGAEvent ({ action, category = null, label = null, value = null, delay = false, extraProperties = {} }) {
      const timeToDelay = delay ? 1001 : 0
      if (window.gtag) {
        const properties = {
          ...extraProperties
        }
        if (category) {
          properties.event_category = category
        }
        if (label) {
          properties.event_label = label
        }
        if (value) {
          properties.value = value
        }
        setTimeout(() => window.gtag('event', action, properties), timeToDelay)
      }
    },
    isElementInViewport (elem) {
      const rect = elem.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom

      // Only completely visible elements return true:
      return elemTop < window.innerHeight && elemBottom >= 0
    },
    isABActivated (name) {
      return this.$exp.name === name && this.$exp.$variantIndexes[0] === 1
    },
    whichABIsActivated (name) {
      return this.$exp.name === name && this.$exp.$variantIndexes[0]
    },
    addDDAction (action, props = {}) {
      if (window.DD_RUM) {
        window.DD_RUM.addAction(action, props)
      }
    },
    addDDError (error, props = {}) {
      if (window.DD_RUM) {
        window.DD_RUM.addError(error, props)
      }
    },
    getStaticsObject (objectPath) {
      if (objectPath.substring(0, 1) !== '/') {
        throw new Error('objectPath need to start with a /')
      }
      return `https://statics.get-base.com${objectPath}`
    }
  }
})
