//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { GOAL_ID_TO_GOAL_KEY } from '@/constants/goalIdByType'

export default {
  props: {
    /**
     * Area page: diet | sex-drivexpanded-e | stress | energy | sleep
     */
    area: {
      type: String,
      required: true
    },
    /**
     * Whether or not the expanded-test is inside the cart or card
     */
    integrated: {
      type: Boolean,
      default: false
    },
    /**
     * Whether or not the expanded-test is inside the cart
     */
    small: {
      type: Boolean,
      default: false
    },
    showPrices: {
      type: Boolean,
      default: true
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    showCaption: {
      type: Boolean,
      default: true
    },
    showCollapsable: {
      type: Boolean,
      default: true
    },
    goalId: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapState('product', ['fastTrackPrices', 'fastTrackTests']),
    ...mapGetters({
      isFastTrackSelected: 'product/isFastTrackSelected'
    }),
    cssVars () {
      return {
        '--background-image': !this.integrated ? `url('${this.getStaticsObject(`/quiz-results/expanded_background_${this.area}.webp`)})` : null
      }
    },
    cssClasses () {
      return {
        'expanded-test--small': this.small,
        'expanded-test--added': this.isFastTrackSelected(this.area),
        'expanded-test--integrated': this.integrated,
        'border-light-grey pr-md-40 pr-sm-16': !this.integrated
      }
    },
    fastTrackPrice () {
      return this.area && this.fastTrackPrices ? this.fastTrackPrices[this.area] : 0
    },
    imageExpanded () {
      let keyGoal = GOAL_ID_TO_GOAL_KEY[this.goalId]

      if (!keyGoal) {
        keyGoal = 'default'
      }

      return this.getStaticsObject(`/quiz-results/expanded_test_module_${keyGoal}.jpg`)
    }
  },
  methods: {
    trackAddFastTrack () {
      if (window.ga) {
        window.ga('send', {
          hitType: 'event',
          eventAction: 'fastTrackAdded',
          eventCategory: 'upsell',
          eventLabel: 'Fast Track added'
        })
      }
    },
    addFastTrack () {
      this.trackAddFastTrack()
      this.$store.dispatch('product/selectFastTrack', this.area)
    },
    removeFastTrack () {
      this.$store.dispatch('product/resetFastTrack')
    }
  }
}
