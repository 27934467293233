//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductConfigurationMixin from '~/mixins/product-configuration'

export default {
  mixins: [ProductConfigurationMixin],
  data () {
    return {
      affiliate: this.$route.params.affiliate || this.$route.query.affiliate,
      isQuizResultsC: this.$route.path === '/quiz-results-c'
    }
  },
  computed: {
    affiliateBannerText () {
      return this.$te(`affiliate.banner.${this.affiliate}`)
        ? this.$t(`affiliate.banner.${this.affiliate}`, { price: this.getPlanPriceRaw('base-improvement-plan') })
        : this.$t('affiliate.banner.default', { code: this.code })
    }
  }
}
